overlay = $(".g-overlay")
modalBG = $(".g-modal-bg")
modal = $(".g-modal-wrapper")
tab = $(".g-peek")
close = $(".g-close")

close.click ->
  overlay.addClass("g-hide")
  modal.addClass("g-hide")
  modalBG.addClass("g-hide")
  tab.removeClass("g-hide")

tab.click ->
  overlay.removeClass("g-hide")
  modal.removeClass("g-hide")
  modalBG.removeClass("g-hide")
  tab.addClass("g-hide")

$("input.subscribe").click ->
  unless $("input.subscribe").hasClass("triggered")
    $("input.subscribe").val("")
  $("input.subscribe").addClass("triggered")

$("input.g-button").click ->
  $("input.g-button").toggleClass("submitted triggered")
  $("input.g-button").val("")
  $(".st0").delay(500).css("opacity", "1")
  setTimeout (->
    $(".st0").css("opacity", "0")
    setTimeout (->
      $("input.g-button").toggleClass("submitted")
      $("input.g-button").delay(100).val("Submitted").html("Submitted")
    ), 1000
  ), 3000